body {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  min-height: 100vh;
}

.App {
  min-height: 100vh;
}
canvas{
  width:100vw;
  height:100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
